import antdData from 'antd/lib/locale-provider/es_ES'
import localeData from 'react-intl/locale-data/es'

const messages = {
  'topBar.title': 'Administrador',
  'topBar.profileMenu.user': 'Usuario',
  'topBar.profileMenu.role': 'ROL',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.logout': 'Cerrar sesión',
  'topBar.profileMenu.ressetPassword': 'Cambiar Contraseña',
  'topBar.profileMenu.printIP': 'Ip impresora',
  'topBar.profileMenu.hello': 'Hola',

  'footer.title': 'Administrador Quqo v',
  'footer.subTitle': 'Compra y Vende todos tus productos en un solo lugar...',
  'footer.url': '©2022 Quqo Back office',

  'authorize.not': 'Acceso no autorizado',
  'authorize.desNot': '¡No tienes derechos para acceder a esta página!',

  'login.language': 'Lenguaje',
  'login.iniciar': 'Iniciar Sesión',
  'login.welcome': 'Bienvenido',
  'login.welcomeQuqo': 'Bienvenido a Quqo Admin',
  'login.invalidEmail': 'Email o contraseña no válidos',
  'login.requiredEmail': 'La entrada no es una dirección de correo electrónico válida',
  'login.requiredEmail2': 'Por favor ingrese su dirección de correo electrónico',
  'login.requiredpass': 'Por favor ingrese su contraseña',
  'login.forgetPassword': 'Se te olvidó tu contraseña',
  'login.login': 'Iniciar Sesión',

  'resetPassword.title': 'ACTUALIZAR CONTRASEÑA',
  'resetPassword.confirmOK': 'Tu contraseña ha sido actualizada con éxito...',
  'resetPassword.newPassword': 'Crea tu nueva contraseña a continuación',
  'resetPassword.actual': 'Contraseña actual',
  'resetPassword.new': 'Nueva contraseña',
  'resetPassword.newConfirm': 'Confirmar contraseña',
  'resetPassword.PleaseConfirm': 'Por favor confirme la contraseña correctamente',
  'resetPassword.noConfirm': '¡Las dos contraseñas que ingresas son inconsistentes!',
  'resetPassword.actualPassword': '¡Por favor ingrese la contraseña actual!',
  'resetPassword.continue': 'Continuar',
  'resetPassword.backToLogin': 'Volver para iniciar sesión',
  'resetPassword.checkCode': 'Verificar Código',
  'resetPassword.emailCode1': 'Acabamos de enviar un código a ',
  'resetPassword.emailCode2': ' Revise su correo electrónico para ver si recibió un mensaje del equipo de Quqo e ingrese el código aquí.',
  'resetPassword.enterCode': 'Ingrese el Código',
  'resetPassword.writeCode': 'Por favor escriba el código que enviamos a su correo',
  'resetPassword.VerifyYourIdentity': 'Verifica tu identidad',
  'resetPassword.title2': 'Restablecer su contraseña',
  'resetPassword.enterNewPassword': 'Por favor ingrese una contraseña nueva',
  'resetPassword.repeatPassword': 'Repita la Contraseña',
  'resetPassword.PleaseRepeatPassword': 'Por favor repita la contraseña',

  'globals.mobilePhone': 'Telefono',
  'globals.attachments': 'Attachments',
  'globals.address': 'Dirección',
  'globals.detail': 'Detalle',
  'globals.name': 'Nombre',
  'globals.email': 'Correo',
  'globals.password': 'Contraseña',
  'globals.credential': 'Documento',
  'globals.StoreCredential': 'Documento',
  'globals.category': 'Categoria',
  'globals.alert': 'Alerta',
  'globals.alertError': 'Error',
  'globals.edit': 'Editar',
  'globals.provider': 'Proveedor',
  'globals.price': 'Precio',
  'globals.TotalPrice': 'Precio Total',
  'globals.deadline': 'Fecha de entrega',
  'globals.store': 'Tienda',
  'globals.status': 'Estado',
  'globals.SeeOrder': 'Ver pedido',
  'globals.send': 'Enviar',
  'globals.cancel': 'Cancelar',
  'globals.ok': 'Si',
  'globals.cancelModal': 'No',
  'globals.okSave': 'Guardar',
  'globals.cancelText': 'Cancelar',
  'globals.total': 'Total',
  'globals.loading': 'Cargando...',
  'globals.product': 'Producto',
  'globals.initialAmount': 'Cantidad Inicial',
  'globals.finalAmount': 'Cantidad Final',
  'globals.AmounttoReceive': 'Cantidad por Recibir',
  'globals.AmountReceive': 'Cantidad Recibida',
  'globals.unitValue': 'Valor unidad',
  'globals.unitValueInitial': 'Valor unidad Inicial',
  'globals.unitValueFinal': 'Valor unidad Final',
  'globals.productImage': 'Imagen del producto',
  'globals.orderHistory': 'Historial del pedido',
  'globals.orderStatusDetail': 'Proceso de picking',
  'globals.noChanges': 'No tiene cambios',
  'globals.columnsControlChanges.title': 'Control de Cambios',
  'globals.columnsControlChanges.date': 'Fecha de Edición',
  'globals.columnsControlChanges.responsable': 'Responsable',
  'globals.columnsControlChanges.reason': 'Razón',
  'globals.quantity': 'Cantidad',
  'globals.points': 'Puntos',
  'globals.totalToPay': 'Total a pagar',
  'globals.pilot': 'Piloto',
  'globals.status.all': 'Todas',
  'globals.status.pending': 'Pendiente de pago',
  'globals.status.confirm': 'Confirmada',
  'globals.status.cancel': 'Cancelada',
  'globals.status.transit': 'En Transito',
  'globals.status.delivered': 'Entregado',
  'globals.status.reagended': 'Reagendada',
  'globals.status.review': 'Revisión',
  'globals.status.deliveryFailed': 'Entrega fallida',
  'globals.filterById': 'Filtrar por id de orden',
  'globals.update': 'Actualizar',
  'globals.filter': 'Filtrar',
  'globals.description': 'Descripción',
  'globals.change': 'Cambiar',
  'globals.zipcode': 'Código postal',
  'globals.city': 'Ciudad',
  'globals.select': 'Seleccione',
  'globals.create': 'Crear',
  'globals.turnOff': 'Apagar',
  'globals.type': 'Tipo',
  'globals.role': 'Rol',
  'globals.accept': 'Aceptar',
  'globals.uploadFile': 'Haga clic o arrastre el archivo a esta área para cargar',
  'globals.date': 'Fecha',
  'globals.searchPlaceholder': 'Selecciona el campo...',
  'globals.addProductEan': 'EAN',
  'globals.textBoxSearch': 'Buscar',
  'globals.delete': 'Eliminar',
  'globals.return': 'Volver',
  'globals.client': 'Cliente',
  'globals.Remove': 'Remover',
  'globals.RemoveProducts': 'Remover productos',
  'globals.searchProducts': 'Buscar ordenes por producto',
  'globals.confirmRemoveProducts': '¿seguro de remover el producto de las ordenes selccionadas?',
  'globals.searchProductsInput': 'Buscando productos...',
  'globals.departament': 'Departamento',
  'globals.Save': 'Guardar',
  'globals.searchBy': 'Buscar por',
  'globals.id': 'ID',
  'globals.required': 'Este campo es requerido',
  'globals.allRequired': 'Todos los campos son requeridos',
  'globals.spanish': '🇨🇴 Español',
  'globals.english': '🇺🇸 Ingles',
  'globals.vietnamita': '🇻🇳 Vietnamita',
  'globals.errorNotification': 'Hubo un error consultando los datos',
  'globals.finished': 'Finalizado',
  'globals.Detail': 'Detalles',
  'globals.coverage': 'Cobertura',
  'globals.orders': 'Órdenes',
  'globals.stops': 'Paradas',
  'globals.download': 'Descargar en EXCEL',
  'globals.downloadLogInventory': 'Descargar en EXCEL log del proceso',
  'globals.orderID': 'Orden ID',
  'globals.userID': 'Usuario ID',
  'globals.options': 'Opciones',
  'globals.mudules': 'Modulos Admin',
  'globals.Localidad': 'Localidad',
  'globals.searchOrder': 'Buscar orden por ID',
  'globals.errorService': 'Hubo un error, intente nuevamente...',
  'globals.neighborhood': 'Barrio',
  'globals.ally': 'Aliado',
  'globals.shipping': 'Envio',
  'globals.quqoStore': 'Tienda',
  'globals.aprobar': 'Aprobar',
  'globals.ver': 'Ver',
  'globals.complete': 'Completar',
  'globals.config': 'Configuración',
  'globals.show': 'Mostrar',
  'globals.number': 'Numero',
  'globals.discount': 'Desconto',
  'globals.detailTC': 'Detalle de TC',
  'globals.NoPermissions': 'Sin permisos',
  'globals.commission': 'Comisión',
  'globals.changeProvider': 'Cambiar de Ciudad',
  'globals.changetype': 'Cambiar (Tienda - Q2C)',
  'globals.changeOrderStatus': 'Cambiar estado de las ordenes',
  'globals.later': 'Mas Tarde',
  'globals.noImage': 'Este producto no tiene imagen',
  'globals.confirm': 'Confirmar',
  'globals.confirmDownload': 'Confirmar descarga',
  'globals.screen': 'Pantalla',
  'globals.help': 'Ayuda',
  'globals.units': 'Unidades',
  'globals.receive': 'Recibida',
  'globals.seeUser': 'Ver usuario',
  'globals.changeProvidersByCountryUser': 'Mostrar solo proveedores del pais del usuario',
  'globals.startDate': 'Fecha de inicio',
  'globals.endTime': 'Fecha final',
  'globals.selectDate': 'Seleccione fecha',
  'globals.reset': 'Reiniciar',
  'globals.downloadText': 'Descargar la orden',
  'globals.updated': 'Actualizado',
  'globals.willUpdate': 'Will Update',
  'orders.order': 'Ordenes',
  'orders.title': 'LISTADO DE ORDENES',
  'orders.subtitle': 'Tabla de ordenes de quqo',
  'orders.filterByStatus': 'Filtrar por estado',
  'orders.downloadPdf': 'Enviar PDFs por Fecha',
  'orders.downloadExcel': 'Enviar Excel por correo',
  'orders.downloadExcelConfirm': '¿Seguro que quieres recibir el Excel con todas las ordenes por correo?',
  'orders.sendPDF': 'Enviar Pdf de las ordenes',
  'orders.selectDate': 'Seleccione la fecha',
  'orders.required.EAN': '¡Por favor ingrese el EAN del producto!',
  'orders.required.email': '¡Por favor ingrese el correo de destino!',
  'orders.neighborhood': 'Barrio',
  'orders.map': 'Ver Mapa',
  'orders.statusStore': 'Detalle Tienda',
  'orders.cash': 'Efectivo',
  'orders.zalopay': 'ZaloPay',
  'orders.linkToPay': 'link To Pay',
  'orders.tc': 'Tarjeta de Crédito',
  'orders.noPaymentMethod': 'No tiene',
  'orders.OrderTotal': 'Desglose de precios',
  'orders.TotalQuantity': 'Cantidad total',
  'orders.PaidwithQuota': 'Pagado con Cupo',
  'orders.PaidwithPoints': 'Pagado con Puntos',
  'orders.PointsUsed': 'Puntos Utilizados',
  'orders.selectDateRequired': 'Debe seleccionar una fecha para Continuar..',
  'orders.OrderDate': 'Fecha de orden',
  'orders.PaymentMethod': 'Metodo de pago',
  'orders.files': 'En unos minutos recibira un correo con los archivos.',
  'orders.DiscountProducts': 'Se descontaron los productos de las ordenes con exito',
  'orders.discountOK': 'Se descontaron los productos de la orden',
  'orders.OrderID': 'ID de la Orden',
  'orders.SelectRes': 'Seleccione un Responsable',
  'orders.totalOrders': 'TOTAL DE ÓRDENES',
  'orders.SelectTheReason': 'Seleccione el motivo',
  'orders.promisedDate': 'Fecha prometida',
  'orders.insuffucientPoints': 'PUNTOS INSUFICIENTES',
  'orders.insuffucientPointsSub': 'La orden fue cancelada con puntos. y actualmente el cliente no cuenta con los puntos necesarios para hacer el cambio de estado.',
  'orders.textRequired': 'Debe seleccionar una razón.',
  'orders.add': 'Agregar',
  'orders.replace': 'Reemplazar',
  'orders.configuration': ' (Con configuración)',
  'orders.noConfiguration': 'No tiene configuraciones',
  'orders.delivery': 'Entrega',
  'orders.promise': 'Promesa',
  'orders.discountByCoupon': 'Descuento por cupón',
  'orders.discounts': 'Descuentos',
  'orders.pendingReview': 'Pendiente a Revisión',
  'orders.approved': 'Aprobado',
  'orders.declined': 'Declinado',
  'orders.reschedule': 'Reagendar orden',
  'orders.rescheduleSub': '¿Esta seguro de reagendar esta orden?',
  'orders.rescheduleQuestion': '¿Esta seguro de reagendar esta orden con motivo de ',
  'orders.changeStatusOrderConform': '¿Seguro de marcar esta orden como entregada?',
  'orders.changeStatusOrderTransit': '¿Seguro de cambiar el estado a transito?',
  'orders.allowCancelOrders': 'Permitir cancelar pedidos',
  'orders.changeOrdersToTransit': 'Cambiar ordenes a transito',
  'orders.markOrderAsDelivered': 'Marcar orden como entregada',
  'orders.markOrderInTransit': 'Marcar orden en transito',
  'orders.changeStatusAll': 'Cambiar estado a ordenes seleccionadas',
  'orders.dateRange': 'Filtrar por rango de fechas de entrega',
  'orders.numberInoviceMessage': 'Guardando el numero de factura.',
  'orders.numberInovice': '# De factura',
  'orders.enterInoviceNumber': 'Ingresa el número de factura',
  'orders.successSaveInoviceNumber': 'El número de factura se ha guardado con éxito.',
  'orders.seeOrdersAllProviders': 'Ver las ordenes de todos los providers',
  'orders.paymentMethod.CREDIT_CARD': 'Tarjeta de Credito',
  'orders.paymentMethod.LINKTOPAY': 'PSE, Efectivo y Billeteras Digitales',
  'orders.paymentMethod.CASH': 'Efectivo',
  'orders.paymentMethod.ZALOPAY': 'ZaloPay',
  'orders.createOrder': 'Crear Orden',
  'orders.actualStatus': 'Estado actual',
  'orders.newStatus': 'Nuevo Estado',
  'orders.results': 'Resultados',
  'orders.purchaseMadeByWhatsapp': 'Compra realizada por el bot desde whatsapp',
  'orders.purchaseMadeByWhatsappType1': 'Tipo de compra: Entrega a domicilio',
  'orders.purchaseMadeByWhatsappType2': 'Tipo de compra: Compra y Recoge',
  'orders.DontCollectMoney': "Don't collect money",

  'ordenDetail.title': 'Items de la orden',
  'ordenDetail.ReasonCancellation': 'Motivo de cancelación',
  'ordenDetail.ReasonReview': 'Motivo de revisión',
  'ordenDetail.transit': 'MAPA DEL PILOTO CON EL PEDIDO HACIA SU DESTINO',

  'ordersItems.title': 'Detalle de la orden',
  'ordersItems.items': 'ITEMS DE ORDENES',
  'ordersItems.message': 'Se eliminaron los items correctamente, recuerde que debe guardar los cambios para ver reflejado en la orden.',
  'ordersItems.messageError': 'No tienes items seleccionados.',
  'ordersItems.confirmDelete': '¿Seguro de Eliminar el Item?',
  'ordersItems.toItemsDelete': 'Eliminar Items',
  'ordersItems.confirm1': '¿Seguro de actualizar la orden?',
  'ordersItems.toSaveChanges': 'Guardar Cambios',
  'ordersItems.confirm2': '¿Seguro de actualizar y confirmar la orden?',
  'ordersItems.toSaveChangesAndDelivery': 'Guardar Cambios y Marcar Como Entregado',
  'ordersItems.messageinf': 'La cantidad no puede ser mayor a la que ya existe.',
  'ordersItems.mensageSuccess': 'Se ha cambiado el estado correctamente.',
  'ordersItems.mensageSuccess2': 'Se ha editado correctamente.',
  'ordersItems.loadingDateChange': 'Cargando el cambio de fecha.',
  'ordersItems.noChanges': 'No tiene cambios.',
  'ordersItems.changeDateDelivery': 'Cambiar Fecha de Entrega',
  'ordersItems.GenetatePDF': 'Generando el PDF de la orden',
  'ordersItems.GenetateZIP': 'Generando el ZIP de archivos para la orden de compra',
  'ordersItems.statusReasonToCancel': 'Predio Cerrado/ Cliente no contactado',
  'ordersItems.statusReasonToCancel2': 'Pedido NO realizado',
  'ordersItems.statusReasonToCancel3': 'Sin Efectivo',
  'ordersItems.statusReasonToCancel4': 'Orden Duplicada',
  'ordersItems.statusReasonToCancel5': 'Calidad de los Productos',
  'ordersItems.statusReasonToCancel6': 'Productos Errados',
  'ordersItems.statusReasonToCancel7': 'Entrega Tardía',
  'ordersItems.statusReasonToCancel8': 'Sin Cobertura',
  'ordersItems.statusReasonToCancel9': 'Desabasto',
  'ordersItems.statusReasonToCancel10': 'Dirección errada',
  'ordersItems.statusReasonToCancel11': 'Pruebas',
  'ordersItems.statusReasonToCancel12': 'Tendero',
  'ordersItems.confirmCancel': '¿Seguro de cancelar el pedido?',
  'ordersItems.cancelOrden': 'Cancelar orden',
  'ordersItems.sendEmailOrden': '¿Desea reeviar el correo de la orden?',
  'ordersItems.sendEmailOrder': 'Enviar al correo',
  'ordersItems.downloadOrderPDF': '¿Desea descargar PDF de la orden?',
  'ordersItems.downloadOrderZIP': '¿Desea descargar el Zip de la orden?',
  'ordersItems.downloadPDF': 'Descargar PDF',
  'ordersItems.downloadZIP': 'Descargar zip',
  'OrdersItems.changeStatus': 'Cargando el cambio de estado',
  'OrdersItems.sameState': 'El estado es el mismo...',
  'ordersItems.changeStatusOrder': 'Cambiar estado de la orden',
  'ordersItems.selectState': 'Seleccione el estado',
  'ordersItems.changeStatusOrderReview': 'Cambiar estado de la revisión',
  'ordersItems.editQuantity': 'Editar la Cantidad del Item',
  'ordersItems.changeQuantity': 'Cambiar Cantidad',
  'ordersItems.name': 'Nombre',
  'ordersItems.pickerModal': 'Seleccione las ordenes para remover el producto',
  'ordersItems.searchOrders': 'Buscar ordenes',
  'ordersItems.status': 'Estado de la orden',
  'ordersItems.removeProducts': 'remover productos',
  'ordersItems.mensageError': 'No hay ordenes con este producto o las que existen estan en status incorrecto',
  'ordersItems.mensageLoading': 'Descontando productos de la orden...',
  'ordersItems.DeliveryDateUpdated': 'Se actualizo la fecha de entrega',
  'ordersItems.pdf': 'Se ha descargado el PDF de ordenes.',
  'ordersItems.zip': 'Se ha descargado el zip con exito.',
  'ordersItems.sendEmail': 'Se ha enviado el correo correctamente.',
  'ordersItems.updateStatus': 'Se actualizo el estado con exito.',
  'ordersItems.inventoryOrders': 'Cantidad inventario',
  'ordersItems.messageRevisionDefault': 'No puede recibirlo. Push de aviso de entrega',
  'ordersItems.remplaceProductTitle': 'Remplazar producto',
  'ordersItems.remplaceProductDescription': 'Seleccione el producto por el cual quieres remplazar',

  'promotions.promotion': 'Promociones',
  'promotions.title': 'Listado de promociones',
  'promotions.subtitle': 'Tabla de promociones de quqo',
  'promotions.all': 'Todas',
  'promotions.acitive': 'Activas',
  'promotions.inactive': 'Inactivas',
  'promotions.textBox': 'Filtrar por Nombre',
  'promotions.filter': 'Filtrar',
  'promotions.newPromotion': 'Crear promoción',
  'promotions.date': 'Fecha de creación',
  'promotions.UpdateDate': 'Fecha de Actualización',
  'promotions.quantityCurrent': 'Cantidad Actual',
  'promotions.titleForm': 'Formulario de promociones',
  'promotions.promoCode': 'Codigo de promoción',
  'promotions.promoCodeRequired': '¡Por favor ingrese el código de promoción!',
  'promotions.public': 'Publica',
  'promotions.Limit': 'Limitada',
  'promotions.totalQuantity': 'Cantidad Total',
  'promotions.totalQuantityRequired': '¡Por favor ingrese la cantidad total en promoción!',
  'promotions.totalUser': 'Cantidad por Usuario',
  'promotions.totalUserRequired': '¡Por favor ingrese la cantidad máxima por usuario!',
  'promotions.productSelect': 'Seleccione el producto',
  'promotions.productSelectRequired': '¡Por favor seleccione un producto!',
  'promotions.normalPrice': 'Precio Normal',
  'promotions.promotionPrice': 'Precio Promoción',
  'promotions.imageChange': 'Cambiar la imagen',
  'promotions.textPromotionSave': 'Para agregar la imagen tienes que guardar primero la promoción',
  'promotions.Save': 'Guardar',
  'promotions.sendNotifications': 'Enviar push',
  'promotions.sendNotificationsOK': 'Seguro de enviar el push',
  'promotions.alertSend': 'Se envio la notificación con exito',
  'promotions.requireName': 'Por favor, introduzca el nombre de la promoción!',
  'promotions.savePromo': 'Se Guardo la nueva promoción con exito',
  'promotions.updatePromo': 'Se Actualizo la promoción con exito.',
  'promotions.ChangeStatus': 'Se ha Cambiado el estado correctamente.',
  'promotions.selectType': 'Seleccione el tipo',
  'promotions.none': 'Ninguno',
  'promotions.product': 'Producto',
  'promotions.departament': 'Departamento',
  'promotions.category': 'Categoria',
  'promotions.combined': 'Combinada',
  'promotions.atLeastOne': 'Banner de multiples productos.',
  'promotions.minAmountListProduct': 'Comprar un monto minimo en productos incluidos en la lista',
  'promotions.createPrmotions': 'Guardar Promoción',
  'promotions.addProduct': 'Agregar Producto',
  'promotions.addPrize': 'Agregar Premio',
  'promotions.confirmDelete': '¿Esta seguro de eliminar este producto?',
  'promotions.confirmNoPrize': '¿Esta seguro de no crear un premio?',
  'promotions.noDelete': 'Debe existir minimo un producto',
  'promotions.tipePrize': 'Tipo de premio',
  'promotions.discount': 'Descuento',
  'promotions.modalConfirm': '¿Esta seguro de guardar esta promoción?',
  'promotions.quantity+': 'Cantidad max por usuario',
  'promotions.quantity-': 'Cantidad Necesaria',
  'promotions.distributeDiscount': 'Debe repartir el descuento en los productos',
  'promotions.successImage': 'Se guardo la imagen con exito',
  'promotions.flashType': 'Tipo de flash',
  'promotions.flashType0': 'No tiene promoción flash',
  'promotions.flashType1': 'Por cantidad',
  'promotions.flashType2': 'Por tiempo',
  'promotions.selectDate': 'Seleccione fecha y hora',
  'promotions.messageErrorDate': 'Debe seleccionar la fecha y hora de finalización',
  'promotions.messageConfigProduct': 'No se puede crear una promoción combinada con productos con configuración, por favor seleccione otro producto para continuar',
  'promotions.showIn': 'Mostrar en: ',
  'promotions.minimumAmount': 'Monto minimo',
  'promotions.promotion1': 'Promoción',
  'promotions.errorPromotoType5': 'Para este tipo de promoción no es posible agregar productos con configuraciones.',
  'promotions.maximumSaleQuantity': 'Cantidad maxima de venta',

  'specialPromotion.buttonText': 'Crear promoción especial',
  'specialPromotion.listProducts': 'Lista de productos',
  'specialPromotion.selector': 'Tipo de promo',
  'specialPromotion.type_selector': 'Tipo de selector',
  'specialPromotion.max_discount_by_user': 'Descuento máximo por usuario',
  'specialPromotion.min_amount': 'Cantidad mínima',
  'specialPromotion.type_gift': 'Tipo de premio',
  'specialPromotion.gift_value': 'Valor del premio',
  'specialPromotion.gift_coupons': 'Cupón',
  'specialPromotion.rules': 'Agregue las reglas',
  'specialPromotion.PROMOTION_PRIZE_PRODUCT': 'Producto',
  'specialPromotion.PROMOTION_PRIZE_DISCOUNT': 'Descuento',
  'specialPromotion.PROMOTION_PRIZE_ROULETTE': 'Ruleta',
  'specialPromotion.PROMOTION_PRIZE_COUPON': 'Cupón',
  'specialPromotion.PROMOTION_PRIZE_CASHBACK': 'Cashback',
  'specialPromotion.PROMOTION_PRIZE_PERCENT': 'Porcentaje',
  'specialPromotion.successCreatePromo': 'Se creo la promoción especial con éxito.',

  'products.product': 'Productos',
  'products.title': 'LISTADO DE PRODUCTOS',
  'products.subtitle': 'Tabla de productos de quqo',
  'products.textBox': 'Filtrar por nombre',
  'products.promo': 'Promo',
  'products.priceCurrent': 'Precio Actual',
  'products.EAN': 'EAN',
  'products.productInPromo': 'Este producto esta en promoción.',
  'products.editProduct': 'Editar Producto',
  'products.requiredName': '¡Por favor ingrese el nombre producto!',
  'products.requiredDescription': '¡Por favor ingrese la descripción del producto!',
  'products.requiredQunatity': '¡Por favor la cantidad!',
  'products.requiredPrice': '¡Por favor ingrese el precio del producto!',
  'products.brand': 'Marca',
  'products.categories': 'Categorias',
  'products.changeImage': 'Cambiar Imagen',
  'products.addImage': 'Agregar Imagen',
  'products.requiredDepartament': 'El departamento es requerido',
  'products.brandDepartament': 'la marca es requerida',
  'products.categoryDepartament': 'la categoria es requerida',
  'products.featured': 'Destacada',
  'products.level': 'Nivel',
  'products.updateLoad': 'Actualizando producto...',
  'products.updateSuccess': 'El producto se actualizo con exito.',
  'Products.confirm': '¿Seguro de actualizar el producto?',
  'Products.confirmCancel': '¿Seguro de cancelar la edición?',
  'Products.descuento': 'Precio con descuento',
  'Products.descuentoMessage': 'El precio de descuento no puede ser mayor al precio base.',
  'Products.max': 'Maximo por usuario',
  'Products.updatedProduct': 'Se ha actualizado el producto',
  'Products.createProduct': 'Se ha agrego el producto al catalogo',
  'Products.manufactured': 'Fabricante',
  'Products.DownloadCatalog': 'DESCARGAR CATALOGO',
  'Products.successChangeStatus': 'Se ha cambiado el estado con exito.',
  'Products.packaging': 'Embalaje',
  'Products.PackagingUnit': 'Unidad del Embalaje',
  'Products.MinimunUnitSell': 'Venta de unidad mínima para inventario. (-1) Ilimitado',
  'Products.productsUpdate': 'Actualizar Producto',
  'Products.addConfigByq2c': 'Esta configuración se agregara al catalogo de Q2C',
  'Products.toBlock': 'Bloquear',
  'Products.tax': 'Impuestos de productos',
  'Products.taxPercent': 'Porcentaje IVA',
  'Products.amountTax': 'Impuesto Consumo',
  'Products.uploadFile': 'Cargar Archivo',
  'Products.downloadFile': 'Descargar Archivo',
  'Products.fileToBill': 'Cargar archivo de impuestos de los productos',
  'Products.presentation': 'Presentación',
  'products.minQuantity': 'Cantidad minima por orden. (0) Sin minimo',
  'products.editPrice': 'Editar precio',
  'products.downloadByEmail': 'El catalogo se enviara mediante correo electronico en un tiempo aproximado de 3 minutos.',
  'products.scales': 'Escalas',
  'products.confirmDelete': '¿Seguro de eliminar la imagen seleccionada?',
  'products.confirmDeleteSuccess': 'Se elimino la imagen con éxito',
  'products.confirmAddSuccess': 'Se agrego la imagen con éxito',

  'product.scales.title': 'Escalas de precios para el producto',
  'product.scales.min_quantity': 'Cantidad mínima',
  'product.scales.confirmSave': '¿Seguro de guardar las escalas?',
  'product.scales.save': 'Guardar cambios',
  'product.scales.addScale': 'Agregar escala',
  'product.scales.messageSuccess': 'Se han guardado las escalas con éxito.',
  'product.scales.errorModal1': 'Este producto tiene escalas de precios. No es posible agregar configuraciones.',
  'product.scales.errorModal2': 'Este producto tiene configuraciones. No es posible agregar escalas de precios, para agregar escalas debes apagar todas los grupos de configuraciones existentes.',
  'product.scales.deleteSuccess': 'Se elimino la escala con exito',
  'product.scales.percentage': 'Porcentaje',
  'product.scales.absolute': 'Absoluto',
  'product.scales.finalPrice': 'Precio final',
  'product.scales.type1': 'Escalas por precio',
  'product.scales.type2': 'Escalas por porcentaje',
  'product.scales.type3': 'Escalas por absoluto',
  'product.createScales': 'Crear Escalas',
  'product.modifyScales': 'Modificar Escalas',

  'group.title': 'Grupo',
  'group.addConfig': 'Agregar configuración al producto',
  'group.variant': 'Variante',
  'group.addition': 'Adición',
  'group.modifier': 'Modificadora',
  'group.minConfiguration': 'Configuración mínima',
  'group.maxConfiguration': 'configuración máxima',
  'group.Required': 'Requerida',
  'group.createGroup': 'Crear Grupo',
  'group.updateGroup': 'Actualizar Grupo',
  'group.newGroup': 'Agregar Nuevo Grupo',
  'group.config': 'Configuraciones',
  'group.extraPrice': 'Precio extra',
  'group.is_default': 'Es predeterminado',
  'group.baseProduct': 'Producto base',
  'group.extraPriceType': 'Tipo de precio extra',
  'group.changePrice': 'Cambiar precio a',
  'group.messageSuccess': 'Se ha actualizado las configuraciones del grupo',
  'group.messageDelete': 'Se ha eliminado las configuracion',
  'group.messageSuccessGroup': 'Se creo el grupo con éxito',
  'group.messageUpdateGroup': 'Se actualizo el grupo con éxito',
  'group.questionCreateGroup': '¿Esta seguro de crear el grupo?',
  'group.questionUpdateGroup': '¿Esta seguro de actualizar el grupo?',
  'group.deleteGroup': 'Eliminar Grupo',
  'group.deleteGroupConfirm': '¿Seguro de eliminar este grupo?',
  'group.noDeleteGroup': 'Debe eliminar todas las configuraciones previamente',
  'group.youHave': 'Tienes',
  'group.specialPrice': 'Precio Especial',
  'group.messageProductBase': 'Debe tener una configuración como producto base',

  'ProductList.Title': 'CATALOGO',
  'ProductList.TitleList': 'Listado del catalogo',
  'ProductList.Confirm': '¿Esta seguro de actualizar el producto?',
  'ProductList.OK': 'Se ha actualizado el producto con exito.',
  'ProductList.form': 'Formulario de productos',

  'catalogUpdate.cataUpdate': 'ACTUALIZAR CATALOGO',
  'catalogUpdate.cataUpdate1': 'Actualizar catalogo',
  'catalogUpdate.cataUpdate2': 'Catalogo Q2C',
  'catalogUpdate.title': 'PROVEEDORES',
  'catalogUpdate.subtitle': 'Seleccione el proveedor el cual desea actualizar el catalogo',
  'catalogUpdate.subtitleQ2C': 'Actualiza y descarga el catalogo para mostrar en el app Q2C',
  'catalogUpdate.viewModel': 'Ver modelo de archivo',
  'catalogUpdate.productsTotal': 'Total de Productos',
  'catalogUpdate.boxUpdateCatalog2': 'Valida que el catalogo a actualizar sea el correcto',
  'catalogUpdate.dragFile': 'Haga clic o arrastre el archivo a esta área para subir',
  'catalogUpdate.boxDragFile': 'Esta seguro de actualizar el catalogo de ',
  'catalogUpdate.boxDragFile2': 'La cantidad de productos en el CSV es',
  'catalogUpdate.boxDragFileLoading': 'El catalogo esta cargando...',
  'catalogUpdate.boxDragFileLoading2': 'Recuerde que esta funcion tarde entre 5-15 minutos',
  'catalogUpdate.boxDragFileLoading3': 'OK',
  'catalogUpdate.text': 'Recuerde que esta funcion tarda entre 5-15 minutos',
  'catalogUpdate.fileSuccess': 'El archivo ha subido correctamente',
  'catalogUpdate.fileError': 'la carga del archivo falló',
  'catalogUpdate.productsTo': 'Productos de quqo',

  'users.infoStores': 'Información de las tiendas',
  'users.user': 'Usuarios',
  'users.title': 'Usuarios',
  'users.subtitle': 'Tabla de usuarios de quqo',
  'users.listUsers': 'Listado de Usuarios',
  'users.whitelist': 'Lista blanca de usuarios',
  'users.search': 'Buscar',
  'users.role.store': 'Tienda',
  'users.role.salesman': 'Vendedor',
  'users.role.pilot': 'Piloto',
  'users.role.admin_warehouse': 'Admin Bodega',
  'users.role.warehouse': 'Bodega',
  'users.role.custom_role': 'Custom Role',
  'users.status.infoStatus1': 'Inactivo',
  'users.status.infoStatus2': 'Activo',
  'users.status.infoStatus3': 'Invalido',
  'users.status.infoStatus4': 'Bloqueado',
  'users.status.infoStatus1s': 'Inactivos',
  'users.status.infoStatus2s': 'Activos',
  'users.status.infoStatus3s': 'Invalidos',
  'users.status.infoStatus4s': 'Bloqueados',
  'users.changeCode1': 'Código para activar clientes',
  'users.changeCode2': 'Crea un código de referido',
  'users.code': 'Debe escribir el código',
  'users.actualCode': 'Codigo Actual',
  'users.changeCodeComercialTextBox': 'Ingrese el Código',
  'users.referralCode': 'Cambiar código de vendedor',
  'users.comercialCode': 'Cambiar código de activación',
  'users.noComercial': 'No Tiene',
  'users.noStore': 'No es una tienda',
  'users.infoCancel': '¿Seguro de cancelar?',
  'users.IDComercial': 'ID Comercial',
  'users.restorePassword': 'Restaurar Contraseña',
  'users.inSendEmail': '¿Seguro de enviar correo al cliente?',
  'users.sendEmail': 'Enviar Correo',
  'users.infoUsers': 'Información del Usuario',
  'users.changeCodeComercial': 'Ingrese el código de comercial',
  'users.changeCodeSalesman': 'Cambiar el Código de referido',
  'users.updaterol': 'Se cambio de rol del usuario exitosamente.',
  'users.updateStatus': 'Se cambio de estado a el usuario exitosamente.',
  'users.updateCode': 'Se cambio el codigo de referido al usuario exitosamente.',
  'users.referralUser': 'Se cambio el codigo de referido al usuario exitosamente.',
  'users.sendEmail2': 'Se ha enviado el correo con exito.',
  'users.updateltlg': 'Se Actualizaron las coordenadas con exito',
  'users.updateUsers': 'Se ha Actualizado el usuario',
  'users.okSave': '¿Esta seguro de actualizar el usuario?',
  'users.uploadFileExito': 'Cargar usuarios de misurtii',
  'users.confirmTextUploadFile': 'Seguro de cargar el archivo XLSX?',
  'users.uploadFile': 'Subir archivo .xlsx',

  'user.address_references': 'Referencias',
  'user.editUsers': 'Formulario de edición de usuarios',
  'user.country_code': 'Indicativo',
  'user.doesNotHave': 'No Tiene',
  'user.doesMotApply': 'No Aplica',
  'user.userValidation': 'Validación de usuarios',
  'user.detailStore': 'Detalle de tienda',
  'user.company': 'Empresa',

  'users.changeToStore': 'Cambiar a tienda',
  'users.nameStore': 'Nombre de la Tienda',
  'users.documentType': 'Tipo de documento',
  'users.details': 'Details',
  'users.general': 'General',
  'users.changeLocation': 'Cambiar Ubicación',
  'users.successCreateStore': 'Se cambio a Tienda con exito',
  'users.questionCreateStore': '¿Seguro de cambiar el usuario a role Tienda?',
  'users.updatePermissions': 'Modificar permisos en el administrador',
  'users.updatePermissionsqrew': 'Modificar permisos en Qrew',
  'users.changePermissionsText': 'Seguro de cambiar los permisos para ',
  'users.changePermissionsSuccess': 'Se han actualizado los permisos del usuario con exito',
  'users.qrew.PROSPECTS_SECTION': 'Prospectos',
  'users.qrew.DELIVERY_ORDERS_SECTION': 'Ordenes de entrega',
  'users.qrew.NEAR_STORES_SECTION': 'Tiendas cercanas comerciales',
  'users.qrew.CONCILIATE_ORDERS_SECTION': 'Ordenes conciliadas',
  'users.qrew.VISIT_SECTION': 'Visitas para comerciales',
  'users.qrew.SUPPORT_SECTION': 'Soporte',
  'users.qrew.PICKER_SECTION': 'Picker',
  'users.qrew.WAREHOUSE_INVENTORY_SECTION': 'Inventario',
  'users.discontinued': 'Suspendido',
  'users.discontinuedDate1': 'Suspendido Desde:',
  'users.discontinuedDate2': 'Suspendido hasta:',
  'users.mobilePhone2': 'Número de contacto adicional',
  'users.country_code2': 'Indicativo de contacto adicional',
  'users.contactNumberError': 'El número de contacto no puede ser el mismo al del usuario.',
  'users.addUserWhiteList': 'Agregar usuario a la lista',
  'users.searchUser': 'Buscar usuario',
  'users.alertToWhiteListUser': 'Confirmar',
  'users.untilDate1': 'Desde la fecha',
  'users.untilDate': 'Hasta la fecha',
  'users.okTextModal': 'Enviar a la lista',
  'users.successWhiteList': 'Se agrego el usuario con exito a la lista.',
  'users.successUpdateWhiteList': 'Se actualizo el usuario con exito.',
  'users.successDeleteWhiteList': 'Se elimino el usuario con exito a la lista.',
  'users.QuestionDeleteWhiteList': '¿Esta seguro de eliminar este usuario de la lista?',
  'users.currentDate': 'Fecha Actual',
  'users.changeDate': 'Cambiar Fecha',
  'users.location': 'Ubicación',
  'users.totalOrdersCancels': 'Ordenes canceladas',
  'users.totalOrdersDelivered': 'Ordenes entregadas',
  'users.returnOfProducts': 'Devolucion de productos',
  'users.totalOrders': 'Total de ordenes',
  'users.userType': 'Tipo de usuario',
  'users.statusUser': 'Estado del usuario',
  'users.suspendedClient': 'Cliente suspendido',
  'users.activate': 'Activar',
  'users.coordinator': 'Listado de coordinadores',
  'users.coordinatorCreate': 'Crear Coordinador',
  'users.coordinatorCreateSuccesss': 'Se creo el nuevo coordinador con exito',
  'users.inheritedProvidersUsers': 'Crear nuevo usuario a provider Hijo',
  'users.inheritedProvidersUsersCreate': 'Crear usuario',
  'users.usersWithProviderCoverage': 'Usuarios con cobertura en el proveedor',

  'trackingComercial.tracking': 'Tracking Comercial',
  'trackingComercial.searchComercial': 'Buscar Comercial',

  'activations.CommercialActivations': 'Activaciones Comerciales',
  'activations.search': 'Buscar Comercial',
  'activations.noUsers': 'Sin usuarios',
  'activations.noName': 'Sin Nombre',
  'activations.NoData': 'Sin datos',

  'Missions.mission': 'Misiones',
  'Missions.mission1': 'Mision',
  'Missions.missionDashboard': 'Dashboard',
  'Missions.poitnsEarned': 'Puntos Ganados',
  'Missions.poitnsUsed': 'Puntos Gastados',
  'Missions.bottom': 'Bottom 10',
  'Missions.top': 'Top 10',
  'Missions.position': 'Posición',
  'Missions.effectiveness': 'Efectividad',
  'Missions.misSubTittle': 'Tabla de misiones',
  'Missions.misSearch': 'Filtrar por tipo',
  'Missions.misSelectTipe': 'Seleccione el tipo',
  'Missions.misSelectTipe1': 'Encuesta',
  'Missions.misSelectTipe2': 'Formulario',
  'Missions.misSelectTipe3': 'Cuestionario',
  'Missions.misSelectTipe4': 'Foto con ubicación',
  'Missions.misFilterRangeDate': 'Filtrar por rango de fecha',
  'Missions.misFilterRangeDate0': 'Últimos 7 Días',
  'Missions.misFilterRangeDate1': 'Últimos 8 Días',
  'Missions.misFilterRangeDate2': 'Últimos 30 Días',
  'Missions.misFilterRangeDate3': 'Últimos 60 Días',
  'Missions.misFilterRangeDate4': 'Últimos 90 Días',
  'Missions.misFilterRangeDate5': 'Últimos 180 Días',
  'Missions.misFilterRangeDate6': 'Último Año',
  'Missions.misFilterRangeDate7': 'Mes en curso',
  'Missions.misFilterRangeDate8': 'Año en curso',
  'Missions.misFilterRangeDate9': 'Personalizado',
  'Missions.misFilterBudget': 'Presupuesto',
  'Missions.misFilterEarnedPoints': 'Gana Puntos',
  'Missions.misFilterBudgetThat': 'Presupuesto que permanece',
  'Missions.misFilterDate': 'Fecha de Caducidad',
  'Missions.misFilterUsers': '# Usuarios Respondieron',
  'Missions.FilterAll': 'Filtrar por data de início e data final',
  'Missions.misCreateMiss': 'Crear Misión',
  'Missions.nameRequired': '¡Por Favor Ingrese un Nombre Para la Misión!',
  'Missions.typeRequired': '¡Por Favor Seleccione un Tipo!',
  'Missions.zoneRequired': '¡Por Favor Seleccione una zona!',
  'Missions.misZones': 'Zona',
  'Missions.misValidation3': '¡Por Favor Ingresa una Description!',
  'Missions.misDateExpiration': 'Fecha de Caducidad',
  'Missions.dateRequired': '¡Por Favor Ingresa la Fecha de Caducidad!',
  'Missions.selectDate': 'Seleccione la fecha',
  'Missions.misBudget': 'Presupuesto',
  'Missions.misPoints': '¿Con Puntos?',
  'Missions.typePrize': 'Tipo de premio',
  'Missions.misPointsResponse': 'Puntos por respuesta',
  'Missions.excerpt': 'Extracto',
  'Missions.publish': 'Publicar',
  'Missions.error1': 'Debe llenar todos los campos',
  'Missions.error2': 'Alguna de las opciones de respuesta está vacia',
  'Missions.success': 'Se publicó la misión con éxito',
  'Missions.modal': '¿Esta seguro de enviar la misión?',
  'Missions.deleteOption': '¿Esta seguro de eliminar esta opción?',
  'Missions.required2Options': 'Debe existir minimo dos tipos de respuesta',
  'Missions.selectCorrect': 'Debe Seleccionar una respuesta correcta.',
  'Missions.saveItem': 'Se ha guardado el item con exito.',
  'Missions.errorSave': 'Debe llenar todos los campos.',
  'Missions.newOptions': 'Nueva Opción',
  'Missions.saveOptions': 'Guardar',
  'Missions.responseText': 'Respuesta de Texto',
  'Missions.responseNumber': 'Respuesta Numérica',
  'Missions.fileToMision': 'Cargar archivo para la misión',
  'Missions.insertText': 'Ingrese el texto',
  'Missions.typeContent': 'Tipo de contenido',
  'Missions.type1': 'Foto',
  'Missions.type2': 'Texto',
  'Missions.type3': 'Archivo',
  'Missions.type4': 'MP4',
  'Missions.question': 'Pregunta',
  'Missions.optionsAll': 'Foto, Texto, Archivo, text...',
  'Missions.tipeOfResponse': 'Tipo de respuesta',
  'Missions.tipeOfResponse1': 'Opción Multiple',
  'Missions.tipeOfResponse2': 'Opción Unica',
  'Missions.tipeOfResponse3': 'Respuesta de Texto',
  'Missions.tipeOfResponse4': 'Respuesta Numérica',
  'Missions.insertQuestion': 'Ingrese una pregunta',
  'Missions.confirmDelete': '¿Desea eliminar la pregunta?',
  'Missions.newQuestion': 'Nueva Pregunta',
  'Missions.BudgetRemaining': 'Presupuesto Restante',
  'Missions.TotalUsers': 'Total Usuarios',
  'Missions.listStores': 'Listado de Tiendas que han Completado la Misión',
  'Missions.ResendMission': 'Reenviar mision',
  'Missions.resendConfirm': '¿Seguro de reenviar la mision?',
  'Missions.sendOK': 'Se reenvio la encuesta a los usuarios',
  'Missions.sendError': 'Hubo un error al reenviaer la encuesta, intenta mas tarde.',
  'Missions.icon': 'Icono',
  'Missions.filterByProvider': 'Filtrar por proveedor',
  'Missions.responses': 'Respuestas',
  'Missions.confirmDownload': 'Descarga el archivo con las respuestas',
  'Missions.buttonDownload': 'Descarga Respuestas en XlSX',
  'Missions.subQuestions': 'Crear sub pregunta',
  'Missions.listSubQuestions': 'Listado de sub preguntas',
  'Missions.deleteSubQuestions': 'Eliminar sub preguntas',
  'Missions.deleteSubQuestionsModal': '¿Seguro de eliminar el grupo de sub preguntas?',
  'Missions.addSubQuestion': 'Nueva Sub pregunta',
  'Missions.quantityLimitUser': 'Limite de respuestas',
  'Missions.available': 'Misión activa',
  'Missions.prize0': 'Ninguno',
  'Missions.prize1': 'Qoins',
  'Missions.prize2': 'Objetos',
  'Missions.namePrize': 'Nombre del premio',
  'Missions.listOfQuestions': 'Listado de preguntas',
  'Missions.ListOfAwards': 'Listado de premios',
  'Missions.quantityInInventory': 'Cantidad en inventario',
  'Missions.languageES': 'en Español 🇪🇸',
  'Missions.languageEN': 'en Ingles 🇺🇸',
  'Missions.languageVI': 'en Vietnamita 🇻🇳',
  'Missions.errorNoLanguage': 'Debe tener al menos un idioma para crear la misión',
  'Missions.errorNoLanguageProvider': 'Debe tener al menos el idioma principal del proveedor seleccionado.',

  'Notifications.title': 'Notificaciones',
  'Notifications.ListNotifications': 'LISTADO DE NOTIFICACIONES',
  'Notifications.newNotification': 'Crear nueva notificación',
  'Notifications.simple': 'Simple',
  'Notifications.advert': 'Anuncio',
  'Notifications.product': 'Producto',
  'Notifications.url': 'URL',
  'Notifications.titleTable': 'Titulo',
  'Notifications.accion': 'Acción',
  'Notifications.newNotifications': 'Crear Notificación',
  'Notifications.requireName': 'Por favor escriba el nombre de la notificación',
  'Notifications.requireDesc': 'Por favor ingresa una descripción',
  'Notifications.public': 'Se publico la notificacion con exito',
  'Notifications.advertImage': 'Imagen: ',
  'Notifications.requideImage': '¡Por favor suba una imagen!',
  'Notifications.advertTextImage2': 'Agrega una imagen a la notificación',
  'Notifications.simpleSelections': 'Seleccione: ',
  'Notifications.requireZone': '¡Por favor Seleccione la zona!',
  'Notifications.documentUser': 'Ingrese el correo del usuario:',
  'Notifications.simpleSendForall': 'Todos',
  'Notifications.storesTo': 'Todas las tiendas de ',
  'Notifications.requireImage': '¡Por favor ingrese el documento del usuario!',
  'Notifications.simpleSendFor': 'Enviar por',
  'Notifications.requireFor': '¡Seleccione a que usuarios va dirigido!',
  'Notifications.simpleSendForZones': 'Localidad',
  'Notifications.simpleSendForUsers': 'Usuarios - Email',
  'Notifications.simpleSendForUsersPhone': 'Usuarios  - Phone Number',
  'Notifications.simpleSendForFile': 'CSV',
  'Notifications.deepLink': 'Deep Link: ',
  'Notifications.productEan': 'Ean',
  'Notifications.requireEan': '¡Por Favor Ingresa el ean del producto Description!',
  'Notifications.uploadFile': 'Agregue un archivo CSV con un listado de correos para el envio de notificaciónes push',
  'Notifications.errorEmails': 'El siguiente no es un correo valido: ',
  'Notifications.list': 'Ver Listado de correos',
  'Notifications.listModal': 'Listado de correos',
  'Notifications.listInput': '(separe los correos por (,) y sin espacios) ',
  'Notifications.listInputPhones': '(separe los correos por (,) y sin espacios) ',
  'Notifications.confirm': '¿Seguro de enviar la notificación?',
  'Notifications.analysis': 'Analisis',
  'Notifications.totalDelivered': 'Total Entregadas',
  'Notifications.totalOpened': 'Total Abiertas',
  'Notifications.PostNow': 'Publicar Ahora',
  'Notifications.scheduleNotification': 'Programar Notificación',
  'Notifications.selectTime': 'Seleccione la Hora',
  'Notifications.double': 'Duplicar',
  'Notifications.banner': 'Banner',
  'Notifications.publishIn': 'Publicar en: ',
  'Notifications.schedule': 'Programada',
  'Notifications.Directly': 'Directamente',
  'Notifications.publishType': 'Tipo de publicación',
  'Notifications.combined': 'Productos Combinados',
  'Notifications.allUsersNoCompleted': 'Solo para los usuarios que no han completado la misión',
  'Notifications.allProviders': 'Todos los proveedores asociados a la misión',
  'Notifications.smsButton': 'Nuevo SMS',
  'Notifications.message': 'Mensaje',
  'Notifications.AllTheCompanies': 'Todas las empresas ',
  'Notifications.SelectCompanies': 'Seleccionar empresas ',
  'Notifications.SelectedCompanies': 'Empresas seleccionadas',
  'Notifications.SendTo': 'Enviado a',
  'Notifications.seeCompanies': 'ver empresas',
  'notifications.sound': 'Sonido',
  'notifications.soundDefault': 'Sonido por defecto',
  'notifications.soundQuqo': 'Sonido de Quqo',
  'notifications.link': 'Link',
  'notifications.mission': 'Misión',
  'notifications.appSections': 'Secciones APP',

  'campaigns.title': 'Campañas',
  'campaigns.subTitle': 'Listado de Campañas',
  'campaigns.new': 'Nueva Campañas',
  'campaigns.new1': 'Nueva Campaña',
  'campaigns.appType': 'Modulo de la app',
  'campaigns.selectChannel': 'Seleccione un canal',
  'campaigns.createCampaign': '¿Seguro de crear esta campaña?',
  'campaigns.createLoading': 'Creando campaña...',
  'campaigns.announce': 'Anuncio',
  'campaigns.promotion': 'Promociones',
  'campaigns.promotion&ids': 'Detalle de una promoción',
  'campaigns.department&ids': 'Departmento',
  'campaigns.category&ids': 'Categoria',
  'campaigns.brand&ids': 'Marca',
  'campaigns.search': 'Buscador',
  'campaigns.scan': 'Código de Barras',
  'campaigns.product&ids': 'Detalle de un Producto',
  'campaigns.combined_product&ids': 'Productos combinados',
  'campaigns.mission': 'Misiones',
  'campaigns.mission&ids': 'Detalle de una Misión',
  'campaigns.qoin': 'Mis Qoins',
  'campaigns.reward': 'Mis premios',
  'campaigns.qpay': 'Qpay',
  'campaigns.support': 'Soporte',
  'campaigns.order': 'Pedidos',
  'campaigns.order&ids': 'Detalle de un Pedido',
  'campaigns.review': 'Califícanos',
  'campaigns.account': 'Mi cuenta',
  'campaigns.channel': 'Canal',

  'Inventory.ListInventory': 'Listado de Inventario',
  'Inventory.tableInventory': 'Tabla de inventario',
  'Inventory.name': 'Nombre de producto',
  'Inventory.title': 'Inventario',
  'Inventory.select4': 'Factura',
  'Inventory.select5': 'Retailer',
  'Inventory.addProduct': 'Agregar Producto',
  'Inventory.subtract': 'Restar Stock a Producto',
  'Inventory.addStock': 'Sumar Stock a Producto',
  'Inventory.messageloading': 'Cargando inventario de productos',
  'Inventory.messageloading2': 'Agregando producto al inventario..',
  'Inventory.messageloading3': 'Restando producto del inventario..',
  'Inventory.selectRetailer': 'Seleccione el retailer.',
  'Inventory.productId': 'Id Producto',
  'Inventory.requirePrice': '¡Por favor ingrese el precio del producto!',
  'Inventory.addProductBuy': 'Compra',
  'Inventory.selectProduct': 'Seleccione un producto!',
  'Inventory.addProducts': 'Producto',
  'Inventory.uploadFileInventory': 'Inventario',
  'Inventory.uploadFileCSV': 'Agregar un nuevo inventario mediante un archivo XLSX.',
  'Inventory.upFile': 'Subir Inventario .xlsx',
  'Inventory.validation': '¿Esta seguro de subir un nuevo inventario?',
  'Inventory.validationConfirm': '¿Esta seguro de subir el archivo?',
  'Inventory.uploadFile': 'Subir archivo de inventario',
  'Inventory.downloadFile': 'Descargar archivo inventario',
  'Inventory.missingProduct': 'Ordenes con Faltantes de Producto',
  'Inventory.missingProduct2': 'Faltante de productos ',
  'Inventory.orderDeleteReasons': 'Agotado',
  'Inventory.orderDeleteReasons2': 'Descontinuado',
  'Inventory.popConfirm': '¿Seguro de marcar eliminar este producto?',
  'Inventory.orderSlopePicker': 'Ordenes Pendientes Por Picker',
  'Inventory.orderID': 'ID orden',
  'Inventory.download': 'Descargar consolidado',
  'Inventory.message': 'Cargando las ordenes pendientes por picker',
  'Inventory.noProducts': 'Sin productos pendientes, ya puedes pasar la orden a lista para preparar.',
  'Inventory.changeStatus': 'Cambiando de estado la orden',
  'Inventory.download2': 'Descargando el listado de productos faltantes en inventario.',
  'Inventory.orderSlopeName': 'Nombre de Picker',
  'Inventory.orderSlopePrice': 'Precio',
  'Inventory.orderSlopeTotal': 'Total Items',
  'Inventory.orderSlopeDate': 'Fecha de entrega',
  'Inventory.orderSlopeDateCreate': 'Fecha de Creación',
  'Inventory.orderSlopeComment': 'Comentario',
  'Inventory.orderSlopeMark': 'Liberar',
  'Inventory.returnOrders': 'Órdenes con productos para devolución',
  'Inventory.orderSlopeReturnDownload': 'Cargando las órdenes con productos para devolución',
  'Inventory.returnRecord': 'Registrando Devolución',
  'Inventory.forInventory': 'Para Inventario',
  'Inventory.DamagedProducts': 'Productos Dañados',
  'Inventory.ProductsNotReceived': 'Productos No Recibidos',
  'Inventory.confirmText': 'Usted está recibiendo estos productos. Confirmar',
  'Inventory.Return': 'Devolución',
  'Inventory.ReturnTableDetail': 'Detalle devolución',
  'Inventory.ReturnDetail': 'Detalle de la Devolución',
  'Inventory.CommittedAmount': 'Cantidad Comprometida',
  'Inventory.ToInventory': 'A Inventario',
  'Inventory.DamagedProduct': 'Producto Dañado',
  'Inventory.DeliveredtoShopkeeper': 'Entregados a Tendero',
  'Inventory.OriginalQuantity': 'Cantidad Original',
  'Inventory.Amounttobereturned': 'Cantidad a Devolver',
  'Inventory.noDevolutions': 'Esta orden no ha tenido devoluciones',
  'Inventory.ActualQuantity': 'Cantidad Actual',
  'Inventory.inicialQuantity': 'Cantidad Inicial',
  'Inventory.productMissing': 'Ordenes con faltante de producto',
  'Inventory.earringsPicker': 'Ordenes pendietes por picker',
  'Inventory.ReturnOrdersTitle': 'Ordenes para devolución',
  'Inventory.Message': 'No se encuentra el ean en el listado de productos.',
  'Inventory.update': 'Se actualizo el inventario con exito.',
  'Inventory.cancel': 'Se cancelo el producto correctamente.',
  'Inventory.last30days': 'Últimos 30 Días',
  'Inventory.DEVOLUCION_PROVEEDOR': 'DEVOLUCION PROVEEDOR',
  'Inventory.AVERIA': 'AVERIA',
  'Inventory.VENCIDO': 'VENCIDO',
  'Inventory.VENTA_INTERNA': 'VENTA INTERNA',
  'Inventory.CONSUMO_INTERNO': 'CONSUMO INTERNO',
  'Inventory.INGRESO_ERRÓNEO': 'INGRESO ERRÓNEO',
  'Inventory.quantityInOrders': 'Cantidad en ordenes',
  'Inventory.virtualQuantity': 'Cantidad virtual',
  'Inventory.virtualQuantityFilter1': 'CRITICO',
  'Inventory.virtualQuantityFilter2': 'NOVEDAD',
  'Inventory.virtualQuantityFilter3': 'SIN NOVEDAD',
  'Inventory.nit': 'Nit: ',
  'Inventory.lastMovement': 'Último movimiento',
  'Inventory.uploadInventoryDesc': 'Este cargue funciona unicamente como resta y suma en el inventario de los productos cargados en el excel, Importante recordar. Quedaran transacciones correspondientes a dicha suma y resta',

  'Brands.Brand': 'Marcas',
  'Brands.Brand1': 'Marca',
  'Brands.tittle': 'Listado de marcas',
  'Brands.search': 'Buscar por nombre',
  'Brands.createBrands': 'Agregar Marca',
  'Brands.notImage': 'Esta marca no tiene imagen',
  'Brands.createBrand': 'Creando la marca',
  'Brands.uploadBrand': 'Actualizando la marca',
  'Brands.createBrandsId': 'ID Marca',
  'Brands.createBrandUpdate': 'Actualizar Nombre',
  'Brands.createBrandImage': 'Imagen',
  'Brands.createBrandImageRequired': '¡Por favor ingrese el precio del producto!',
  'Brands.createBrandUploadImage2': 'Actualizar la imagen de la marca',
  'Brands.createBrandNameRequired': '¡Por favor ingrese el nombre de la marca!',
  'Brands.BrandImage': 'Imagen de la marca',
  'Brands.uploadFileBrands': 'Subir Marcas',
  'Brands.uploadFileSubtitle': 'Agregar un nuevo CSV de marcas',
  'Brands.createBrandsUpload': '¿Esta seguro de subir un nuevo XLSX de marcas?',
  'Brands.finish': 'Se cargo el archivo con exito.',

  'Zones.zone': 'Zonas',
  'Zones.list': 'Listado',
  'Zones.Maps': 'Mapa',
  'Zones.searchName': 'Buscar por nombre',
  'Zones.search': 'Buscar',
  'Zones.Sincronizar': 'Sincronizar tiendas de ',
  'Zones.addZone': 'Agregar zona',
  'Zones.Sincronizar1': '¿Esta seguro de sincronizar tiendas zonas de ',
  'Zones.Sincronizar2': 'Sincronizando las tiendas con las zonas...',
  'Zones.selectZonesStores': 'Seleccione las zonas para mostrar las tiendas',
  'Zones.selectZone': 'Seleccione la zona',
  'Zones.country': 'País',
  'Zones.change': 'Usted cambio las cordenadas!',
  'Zones.question': '¿Desea cancelar o actualizar?',
  'Zones.RequiredName': '¡Por favor ingrese el nombre de la zona!',
  'Zones.RequiredLocalidad': '¡Por favor seleccione la localidad de la zona!',
  'Zones.form': 'Formulario de zonas',
  'Zones.createZone': 'Se ha creado la zona con exito.',
  'Zones.Update': 'Se ha editado la zona con exito.',
  'Zones.listStores': 'Listado de tiendas',
  'Zones.editConfigZone': 'Editar configuracion de la zona',

  'holidays.holidays': 'Días festivos',
  'holidays.listHolidays': 'Listado de días festivos',
  'holidays.addHolidays': 'Agregar dia festivo',
  'holidays.new': 'Nuevo dia festivo',
  'holidays.edit': 'Editar dia festivo',
  'holidays.requiredDate': 'La fecha es requerida',
  'holidays.alertSuccess': 'Se creo un nuevo dia festivo',
  'holidays.updateSuccess': 'Se actualizo la fecha del festivo',
  'holidays.create': 'Creando un nuevo dia festivo.',
  'holidays.update': 'Actualizando la fecha...',
  'holidays.confirmDelete': '¿Seguro de eliminar este dia festivo?',
  'holidays.actual': 'Actual: ',
  'holidays.alertdelete': 'Se elimino el festivo con exito',
  'holidays.dayHoliday': 'Dia Festivo',

  'providers.title': 'Proveedores',
  'providers.list': 'Listado de proveedores',
  'providers.create': 'Formulario de proveedor',
  'providers.daylyCut': 'Hora de corte',
  'providers.image': 'Imagen del proveedor',
  'providers.minAmount': 'Compra minima',
  'providers.pointValue': 'Puntos regalo',
  'providers.laborableDays': 'Dias Laborables',
  'providers.laborableDeliveryDays': 'Días de entrega laborables',
  'providers.appReference': 'Referencia de Aplicación',
  'providers.currency': 'Moneda',
  'providers.initialBonus': 'Bono inicial en puntos',
  'providers.pointsValue': 'Valor por punto',
  'providers.deliveryDays': 'Dia de entrega',
  'providers.delivery_information': 'Información de entrega',
  'providers.billing_information': 'Datos de facturación',
  'providers.language': 'Lenguaje',
  'providers.emailPDF': 'Correos',
  'providers.confirm': '¿Esta seguro de enviar los datos de este proveedor?',
  'providers.earningRate': 'Multiplicador',
  'providers.minPoints': 'Minimo de puntos para compra',
  'providers.loading': 'Creando proveedor...',
  'providers.loadingUpdate': 'Actualizando proveedor...',
  'providers.successAlert': 'Se agrego un nuevo proveedor',
  'providers.successEditAlert': 'Se actualizo el proveedor',
  'providers.buttonSend': 'Enviar',
  'providers.max_slots_calendar': 'Cantidad de dias en calendario',
  'providers.type_of_shipping_cost': 'Tipo de costo de envio',
  'providers.default_shipping_cost': 'Costo de envío predeterminado',
  'providers.big_amount': 'Monto Alerta Orden',
  'providers.daily_cut': 'Hora de corte',
  'providers.max_orders_day': 'Cantidad maxima de ordenes por dia',
  'providers.max_delivery_days': 'Días máximos de entrega',
  'providers.parent_provider': 'Proveedor padre',
  'providers.min_boxes': 'Cajas minimas',
  'providers.catalog_boxes': '¿cajas minimas?',
  'providers.timezone': 'Timezone',
  'providers.inheritance_distance': 'Rango de entregas en metros',
  'providers.type_coverage': 'Seleccione el tipo de cobertura',
  'providers.delivery_push_notification': 'Notificar a usuario 1 dia antes de la entrega',
  'providers.poits_form': 'Puntos',
  'providers.polygono': 'Polígono',
  'providers.show_powered_by': 'Mostrar nombre del proveedor',
  'providers.block_assign_pilot_order_before_date': 'Bloqueo en asignación a pilotos', // NUEVa
  'providers.help': 'Nombre del proveedor',
  'providers.help1': 'Seleccione el país para el cual sera el proveedor',
  'providers.help2': 'Si es un provider Hijo debe seleccionar su provider padre.',
  'providers.help3': 'Seleccione la zona horaria de la región del proveedor',
  'providers.help4': 'Ingrese el tipo de moneda del país ejemplo: COP',
  'providers.help5': 'Ingrese la compra mínima para el proveedor (en pesos)',
  'providers.help6': 'Ingrese el bono inicial en puntos para nuevos usuarios',
  'providers.help7': 'Ingrese el valor de un punto en pesos',
  'providers.help8': 'Ingrese el valor del multiplicador de puntos a pesos.',
  'providers.help9': 'Ingrese el valor mínimo de puntos para una compra',
  'providers.help10': 'Seleccione el lenguaje principal para los usuarios',
  'providers.help11': 'Ingrese los correos para el envio del PDF de las ordenes separados por , ejemplo: admin@quqo.com,admin2@quqo.com',
  'providers.help12': 'Cantidad de dias visibles en el calendario',
  'providers.help13': 'Ingrese el costo de envio de la orden cuando no cumple con la compra mínima para envío gratis',
  'providers.help14': 'Ingrese el costo maximo de una orden para alertar via email',
  'providers.help15': 'Maximo de ordenes por dia',
  'providers.help16': 'Ingrese los dias para hacer entregas',
  'providers.help17': '¿El provider vende por minimo de cajas?',
  'providers.help18': 'Si el proveedor vende por numero de cajas ¿cuantas cajas es el minimo?',
  'providers.help19': 'Ingrese el tope maximo en dias que será respetado por el calendario para entrega de ordenes',
  'providers.help20': 'Ingrese los dias laborables separados por coma (,) 0,1,2,3,4,5,6 donde: Lunes: 1, martes:2 ... Domingo:0',
  'providers.help21': 'Ingrese la hora de corte del provider formato 24 hrs',
  'providers.help22': 'Estado del proveedor (Encendido o apagado)',
  'providers.help23': 'Ingrese el rango de entregas en metros ejemplo: 30',
  'providers.help24': 'Marque activo si desea notificar un dia antes con una notificación push al cliente que se enviara su pedido',
  'providers.help25': 'Habilita/Deshabilita el uso de puntos en el proveedor',
  'providers.help26': 'Seleccione las localidades que desea asociar a su ciudad',
  'providers.help27': 'Seleccione el tipo de cobertura que desea tener (localidades - rango en metros)',
  'providers.help28': 'Seleccione el poligono para el cual va a estar disponible el proveedor.',
  'providers.help29': 'Ingrese el minimo requerido para la recompra',
  'providers.help30': 'Mostrar el nombre del proveedor en los productos.',
  'providers.help31': 'Bloquear asignaciones de ordenes a pilotos antes de la fecha de entrega', // Nueva
  'providers.help32': 'Selecciona el tipo de costo de envio: Plano= Un mismo precio para cualquier pedido. Porcentaje= Porcentaje total del pedido', // Nueva
  'providers.help33': 'Ingresa los días de entrega laborables para este proveedor',
  'providers.help34': 'Ingresa el nombre de la aplicación en la que estara disponible el proveedor',
  'providers.polygonUpdateConfirm': '¿Seguro de actualizar el polígono de cobertura para este provider?',
  'providers.selectPolygon': 'Seleccionar Polígono',
  'providers.minAmountRepurchase': 'Minimo requerido para la recompra',
  'providers.flat': 'Plano',
  'providers.percentage': 'Porcentual',

  'retailers.title': 'Retailers',
  'retailers.list': 'Listado de Retailers',
  'retailers.create': 'Formulario de Retailer',
  'retailers.isWholesaler': 'Es Mayorista',
  'retailers.confirm': '¿Esta seguro de enviar los datos de este retailer?',
  'retailers.successAlert': 'Se actulizo los retailers con exito',
  'retailers.save': 'Guardar Registro',

  'proccess.title': 'Picking',
  'proccess.subtitle': 'Listado de Procesos de Picking',
  'proccess.send1': 'Esta seguro de crear el proceso?',
  'proccess.send2': 'Para la fecha: ',
  'proccess.error': 'Debe seleccionar una fecha para continuar',
  'proccess.error2': 'Asegúrese de que no existe proceso activo.',
  'proccess.success': 'Se creo el proceso con exito',
  'proccess.selectDate': 'Realizar picking de las órdenes para entregar el día: ',
  'proccess.routeOptimization': 'Optimización de rutas',
  'proccess.ListRoute': 'Listado de rutas',
  'proccess.route': 'Ruta',
  'proccess.procces': 'Proceso',
  'proccess.rute': 'Ruta para el piloto',
  'proccess.stimatedTime': 'Tiempo estimado',

  'inventoryprocess.title': 'Proceso de Inventario',
  'inventoryprocess.subtitle': 'Listado de Procesos de Inventario',
  'inventoryprocess.sure': 'Se creará un nuevo proceso de inventario para el día actual',
  'inventoryprocess.turnOffMessage': '¿Seguro desea apagar el proceso?',
  'inventoryprocess.turnOnMessage': '¿Seguro desea encender el proceso?',
  'inventoryprocess.restartMessage': '¿Seguro desea reiniciar el proceso?',
  'inventoryprocess.turnOffMessageOK': 'Proceso cerrado exitosamente',
  'inventoryprocess.restartMessageOK': 'Proceso reiniciado exitosamente',
  'inventoryprocess.printMessage': '¿seguro desea imprimir la orden?',
  'inventoryprocess.printMassive': 'Impresion Masiva',

  'picker.available': 'Sin Asignar',
  'picker.pending': 'Pendiente',
  'picker.pending1': 'Pendientes',
  'picker.inProcess': 'En Proceso',
  'picker.Finished': 'Terminada',
  'picker.Finished1': 'Terminadas',
  'picker.Print': 'Imprimir',
  'picker.Forwarded': 'Proceso reenviado al picker',
  'picker.Restart': 'Reiniciar Proceso',
  'picker.changeStatusMessage': '¿Seguro desea cambiar el estado?',
  'picker.NoEdit': 'La orden no puede ser editada',
  'picker.OverdueOrders': 'Órdenes Atrasadas',
  'picker.Ranking': 'Ranking Pickers',
  'picker.viewListRoutes': 'Listado de rutas',
  'picker.viewListOrders': 'Detalle de órdenes',
  'picker.position': 'Posición',
  'picker.duration': 'Duración',
  'picker.distance': 'Distancia',
  'picker.vehicle': 'Vehiculo',
  'picker.detailMarker': 'Haga clic en el marcador para ver más detalles de la entrega.',
  'picker.freeDate': 'Fecha libre',

  'supplies.supplies': 'Suministros',
  'supplies.title': 'Listado de Suministros',
  'supplies.addSupplies': 'Agregar',
  'supplies.formSupplies': 'Formulario de suministros',
  'supplies.buttonADD': '¿Esta seguro de enviar los datos de este suministro?',
  'supplies.createLoading': 'Creando suministro...',
  'supplies.updateLoading': 'Actualizando suministro...',
  'supplies.deleteModal': '¿Seguro de eliminar el suministro?',
  'supplies.delete': 'Eliminar',

  'localities.title': 'Localidades',
  'localities.titleList': 'Listado de localidades',
  'localities.display_order': 'Orden de visualización',
  'localities.intermediate_days': 'Días intermedios',
  'localities.delivery_days': 'Dias de entrega',
  'localities.editModal': 'Editar localidad',
  'localities.createModal': 'Crear localidad',
  'localities.addModal': 'Agregar localidades',
  'localities.createLoading': 'Creando localidad...',
  'localities.EditandoLoading': 'Editando localidad...',
  'localities.alertSuccess': 'Se ha creado la nueva localidad con exito',
  'localities.alertSuccessEdit': 'Se ha actualizado la localidad con exito',
  'localities.confirmText': '¿Esta seguro de eliminar esta localidad?',
  'localities.alertdelete': 'Se elimino la localidad con exito.',
  'localities.min_order_amount': 'Monto minimo orden',
  'localities.min_order_repurchase_amount': 'Monto minimo para recompra',
  'localities.min_price_for_free_shipping': 'Monto minimo para envio gratis',
  'localities.first_order_min_price_for_free_shipping': 'Monto minimo 1era orden',
  'localities.shipping_cost': 'Costo de envío',
  'localities.customer_shipping_cost': 'Costo de envío del cliente',
  'localities.shipping_cost_percent': 'Porcentaje del total de la orden',
  'localities.customer_min_amount': 'Importe mínimo del cliente',
  'localities.qtc_active': '¿Qtc activo?',
  'localities.available_time_qtc': 'Tiempo disponible qtc',
  'localities.closing_reason': 'Motivo de cierre',
  'localities.extra_delivery_days': 'Días de entrega adicionales',
  'localities.min_amount_extra_delivery_days': 'Cantidad mínima de días de entrega adicionales',
  'localities.help1': 'Nombre de la localidad',
  'localities.help2': 'Seleccione el proveedor',
  'localities.help3': 'Ingrese el costo minimo para la compra minima.',
  'localities.help4': 'Ingrese el monto minimo para la recompra.',
  'localities.help5': 'Ingrese el monto minimo para la envio gratis.',
  'localities.help6': 'Ingrese el monto minimo para el envio gratis de la orden den el primer envio.',
  'localities.help7': 'Ingrese el orden de visualización para el orden de las ordenes en el picking',
  'localities.help8': '1=Lunes,2=Martes,3=Miercoles,4=Jueves,5=Viernes,6=Sabado,0=Domingo.',
  'localities.help9': '1=Entregas hoy(12H), 2=Entregas mañana(24H), 3=Entrega pasado mañana(48H)',
  'localities.help10': 'Costo de envío',
  'localities.help11': 'Costo de envío del cliente',
  'localities.help12': 'Importe mínimo del cliente',
  'localities.help13': '¿Qtc activo?',
  'localities.help14': 'Tiempo disponible qtc',
  'localities.help15': 'Motivo de cierre',
  'localities.help16': 'Porcentaje de la orden para el costo de envío ',

  'zipCodes.title': 'Codigos Postales',
  'zipCodes.list': 'Listado de Codigos Postales',
  'zipCodes.create': 'Crear nuevo',
  'zipCodes.placeholder': 'Buscar código postal',
  'zipCodes.titleModal': 'Crear Nuevo código postal',
  'zipCodes.editModal': 'Editar código postal',
  'zipCodes.code': 'Código',
  'zipCodes.codeRequired': 'El código es requerido',
  'zipCodes.confirmDelete': '¿Seguro de eliminar este el codigo postal?',
  'zipCodes.createLoading': 'Creando codigo postal...',
  'zipCodes.EditandoLoading': 'Editando el codigo postal...',
  'zipCodes.alertSuccess': 'Se guardo el registro con exito',
  'zipCodes.alertdelete': 'Se elimino el codigo postal con exito',

  'cities.title': 'Ciudades',
  'cities.titleList': 'Listado de ciudades',
  'cities.deleteMessage': '¿Seguro de eliminar esta ciudad?',
  'cities.createModal': 'Crear ciudad',
  'cities.editCity': 'Editar Ciudad',
  'cities.alertdelete': 'Se elimino la ciudad con exito!',
  'cities.createLoading': 'Creando ciudad....',
  'cities.EditandoLoading': 'Editando ciudad....',
  'cities.alertSuccessEdit': 'Se ha editado la ciudad con exito.',
  'cities.alertSuccess': 'Se ha creado la ciudad con exito.',
  'cities.NoCountry': 'No tiene País',
  'cities.showAll': 'Mostrar ciudades sin proveedor',

  'neighboarhood.title': 'Barrios',
  'neighboarhood.titleList': 'Listado de barrios',
  'neighboarhood.createModal': 'Crear barrio',
  'neighboarhood.editModal': 'Editar barrio',
  'neighboarhood.confirmText': '¿Seguro de eliminar este barrio?',
  'neighboarhood.alertdelete': 'Se elimino el barrio correctamente.',
  'neighboarhood.alertSuccessEdit': 'Se ha actializado el barrio con exito',
  'neighboarhood.EditandoLoading': 'Editando barrio...',
  'neighboarhood.createLoading': 'Creando barrio...',
  'neighboarhood.alertSuccess': 'Se ha creado el barrio con exito',

  'countries.title': 'Paises',
  'countries.country': 'País',
  'countries.titleList': 'Listado de paises',
  'countries.iso': 'ISO',
  'countries.alertdelete': 'Se elimino el país correctamente.',
  'countries.editModal': 'Editar país',
  'countries.createModal': 'Crear País',
  'countries.createLoading': 'Creando país...',
  'countries.alertSuccess': 'Se ha creado el nuevo país con exito',
  'countries.EditandoLoading': 'Editando país...',
  'countries.alertSuccessEdit': 'Se ha actualizado el país con exito',
  'countries.confirmText': '¿Esta seguro de eliminar esta país?',
  'countries.imageURL': 'URL imagen',

  'dailyCommitment.title': 'Compromiso diario',
  'dailyCommitment.subTitle': 'Compromiso diario de comerciales',
  'dailyCommitment.activities': 'Activaciones',
  'dailyCommitment.commitment': 'Compromiso',
  'dailyCommitment.reactivations': 'Reactivaciones',
  'dailyCommitment.FirstOrder': 'Primer Pedido',
  'dailyCommitment.Activation': 'Activación',
  'dailyCommitment.Reactivation': 'Reactivación',

  'salesman.title': 'Comerciales',
  'salesman.VisitToStores': 'Proceso de visitas',
  'salesman.necessarySalesman': 'Rutas necesarios',
  'salesman.routes': 'Rutas',
  'salesman.storesBySalesman': 'Visitas por vendedor',
  'salesman.detailByProcess': 'Detalle del proceso # ',
  'salesman.createProcess': 'Crear proceso',
  'salesman.salesman1': '¿Cuántas visitas por vendedor?',
  'salesman.createOK': '¿Seguro de crear un proceso para este proveedor?',
  'salesman.mapModal': 'Mapa de la ruta #',
  'salesman.ToAssign': 'Asignar',
  'salesman.ToAssignModal': 'Crear nueva ruta',
  'salesman.selectSalesman': 'Seleccione el vendedor: ',
  'salesman.selectRequired': 'Debe Seleccionar un vendedor para asignarlo a la ruta',
  'salesman.salesman': 'Vendedor',
  'salesman.salesmans': 'Vendedores',
  'salesman.stores': 'Tiendas',
  'salesman.success': 'Se asigno el vendedor a la ruta con exito',
  'salesman.noName': 'Sin asignar',
  'salesman.noNumber': 'Debe insetar cuantas tiendas se deben visitar por vendedor',
  'salesman.noMore': 'No se puede asignar mas de 24 tiendas a las rutas',
  'salesman.dateLastOrder': 'Fecha del último pedido',
  'salesman.CreatePolygon': 'Crea un poligono para asignar un nuevo procesos de visita',
  'salesman.Finalize': 'Finalizar',
  'salesman.searchStores': 'Buscar tiendas',
  'salesman.deleteRoute': '¿Esta seguro de eliminar la ruta?',
  'salesman.errorNoStores': 'Esta ruta no tiene tiendas asignadas',
  'salesman.detailMap2': 'Mapa completo de las rutas',
  'salesman.selecrtSalesmans': 'Seleccione el vendedor para mostrar sus tiendas',
  'salesman.deleteMessage': '¿Seguro de eliminar el processo?',
  'salesman.successDelete': 'Se elimino el proceso con exito!',
  'salesman.createNewRoute': 'Crear ruta por poligono',
  'salesman.createNewRouteXlsx': 'Crear ruta por lista de tiendas',
  'salesman.createRoutesuccess': 'Se ha creado la ruta con exito !',
  'salesman.detailMap': 'Ver mapa completo',

  'givepointsOrders.title': 'Regala puntos por ordenes',
  'givepointsOrders.subTitle': 'Módulo para dar puntos a tiendas',
  'givepointsOrders.button': 'Regalar puntos',
  'givepointsOrders.detailTransactions': 'Detalle de la transacción',
  'givepointsOrders.textConfirm': '¿Estás seguro de dar los puntos a la tienda?',
  'givepointsOrders.responsable': 'Responsable',
  'givepointsOrders.noResponsable': 'Sin responsable',
  'givepointsOrders.desc1': 'Producto en mal estado',
  'givepointsOrders.desc2': 'Producto errado',
  'givepointsOrders.desc3': 'Producto faltante',
  'givepointsOrders.desc4': 'Error de precio en catálogo',
  'givepointsOrders.desc5': 'Compensación de costo de envío',
  'givepointsOrders.desc6': 'Pedido entregado en otra tienda',

  'permissions.title': 'Sección de permisos del administrador por rol',
  'permissions.createRole': 'Crear un nuevo rol',
  'permissions.role': 'Rol',
  'permissions.permissions': 'permisos',
  'permissions.success': 'Se ha creado el nuevo rol con exito',
  'permissions.popConfirm': '¿Seguro de cambiar el permiso para este rol?',
  'permissions.popConfirmUser': '¿Seguro de cambiar el permiso para este usuario?',
  'permissions.exito': 'Se cambio los permisos con exito',

  'Q2CTitle': 'Q2C',
  'Q2CTitle.detail': 'Detalle de la tienda',

  'Q2CCatalog.update': 'CATALOGO POR ALIADO',
  'Q2CCatalog.ally': 'Seleccione el aliado',
  'Q2CCatalog.locality': 'Seleccione la localidad',

  'Q2COrders.status0': 'Pendiente',
  'Q2COrders.status1': 'Aceptada',
  'Q2COrders.status4': 'Entregada',
  'Q2COrders.confirm': '¿Seguro de cambiar el estado a la orden?',

  'registrationPurchaseOrders.title': 'Registro de Ordenes de Compra',
  'registrationPurchaseOrders.approvePurchaseOrders': ' aprobar ordenes de compra',
  'registrationPurchaseOrders.approvePurchaseOrdersAllCities': 'Aprobar ordenes de compra de todas las ciudades',
  'registrationPurchaseOrders.subTitle': 'Formulario de Registro de Ordenes de Compra',
  'registrationPurchaseOrders.listTitle': 'Listado de Registros de Ordenes de Compra',
  'registrationPurchaseOrders.confirmDelete': '¿Desea eliminar este producto?',
  'registrationPurchaseOrders.messageProducts': 'Primero debes guardar todos los productos para continuar',
  'registrationPurchaseOrders.addRegister': 'Agregar Registro',
  'registrationPurchaseOrders.saveOrder': 'Guardar el Registro de Compra',
  'registrationPurchaseOrders.warehouse': 'Seleccione el almacen:',
  'registrationPurchaseOrders.cancelOrder': 'Debe ingresar la razón de cancelamiento',
  'registrationPurchaseOrders.questionModal': 'Esta seguro de guardar este registro de compra',
  'registrationPurchaseOrders.questionModal2': 'Esta seguro de actualizar este registro de compra',
  'registrationPurchaseOrders.success': 'Se ha guardado el registro de la compra con exito',
  'registrationPurchaseOrders.confirmAproved': '¿Seguro de aprobar esta orden?',
  'registrationPurchaseOrders.confirmCancel': '¿Seguro de cancelar esta orden?',
  'registrationPurchaseOrders.confirmDelivered': '¿Desea marcar esta orden como entregada?',
  'registrationPurchaseOrders.confirmDelivered2': 'Todos los items quedaran registrados como recibidos',
  'registrationPurchaseOrders.confirmSuccesfull': 'Esta orden se ha entregado con exito',
  'registrationPurchaseOrders.buttonSend': 'Marcar orden como entregada',
  'registrationPurchaseOrders.confirmAprovedSuccess': 'Se marco la orden como aprobada',
  'registrationPurchaseOrders.confirmAprovedCancel': 'Se marco la orden como cancelada',
  'registrationPurchaseOrders.detailOrder': 'Detalle de la orden de compra',
  'registrationPurchaseOrders.totalReceived': 'Total Recibido',
  'registrationPurchaseOrders.invoiceRequired': 'Debe tener un numero de factura para cambiar a entregada',
  'registrationPurchaseOrders.updateOrder': 'Actualizar Orden',
  'registrationPurchaseOrders.noSaved': 'Hay productos que no cumplen con los campos requeridos',
  'registrationPurchaseOrders.EditAndreceive': 'Editar y Recibir',
  'registrationPurchaseOrders.receive': 'Recibir Producto',
  'registrationPurchaseOrders.DeliveryType': 'Tipo de entrega',
  'registrationPurchaseOrders.PartialDelivery': 'Entrega Parcial',
  'registrationPurchaseOrders.FinalDelivery': 'Entrega Final',
  'registrationPurchaseOrders.ConfirmDelivery': 'Confirmar Entrega',
  'registrationPurchaseOrders.recivedProduct': '¿Esta seguro de recibir este producto?',
  'registrationPurchaseOrders.observationNull': 'Debe escribir una observación del cambio de valores',
  'registrationPurchaseOrders.typeNull': 'Debe seleccionar un tipo de entrega',
  'registrationPurchaseOrders.InvoiceNull': 'Esta orden no tiene número de factura',
  'registrationPurchaseOrders.InvoiceMessage': 'Agrega la factura y actualiza la orden para continuar...',
  'registrationPurchaseOrders.succesItem': 'Se agregaron los productos al inventario con exito.',
  'registrationPurchaseOrders.noProducts': 'Productos no encontrados',
  'registrationPurchaseOrders.messageError': 'Recuerda agregar las especificaciones a los productos.',
  'registrationPurchaseOrders.note': 'NOTA: Los productos marcados con color amarillo requieren seleccionar las especificaciones para el cargue de la orden de compra',
  'registrationPurchaseOrders.addDiscount': 'Agregar Descuento',
  'registrationPurchaseOrders.addDiscount2': 'Agregar Descuento a la orden ',
  'registrationPurchaseOrders.followingPurchaseOrder': 'Se crearon las siguientes ordenes de compra con exito',
  'registrationPurchaseOrders.confirmAddDiscount': '¿Esta seguro de agregar el descuento a esta orden?',
  'registrationPurchaseOrders.errorRecibeOrder': 'Esta orden ya tiene todos sus productos en estado recibido',
  'registrationPurchaseOrders.runBillingByDateRange': 'Ejecutar facturación por rango de fechas',
  'registrationPurchaseOrders.range': 'Seleccione el rango de fechas',
  'registrationPurchaseOrders.selectProviders': 'Seleccione los proveedores',
  'registrationPurchaseOrders.confirmModalRanges': '¿Seguro de ejecutar facturación por rango de fechas?',
  'registrationPurchaseOrders.successCreateRanges': 'Se ha guardado con exito los rangos de fecha para facturación.',
  'registrationPurchaseOrders.selectFileXlsx': 'Seleccionar el archivo de productos (XLSX)',

  'r.form.billingInformation': 'Información de facturación',
  'r.form.deliveryInformation': 'Información de entrega',
  'r.form.approved': 'Aprobado por',
  'r.form.observations': 'Observaciones',
  'r.form.originalDeliveryDate': 'Fecha de entrega original',
  'r.form.deliveryDate': 'Fecha de entrega',
  'r.form.eanSales': 'Ventas de ean',
  'r.form.eanPacking': 'Embalaje de ean',
  'r.form.packing': 'Embalaje',
  'r.form.totalTaxes': 'Impuestos totales',
  'r.form.subTotalAmount': 'Sub total',
  'r.form.newProduct': 'Nuevo Producto',
  'r.form.InventoryDays': 'Dias de inventario',
  'r.status.0': 'Borrador',
  'r.status.1': 'Pendiente por Aprobar',
  'r.status.2': 'Aprobado',
  'r.status.3': 'Cancelado',
  'r.status.4': 'Recibido',
  'r.status.5': 'Completada',

  'appComparative.title': 'Reporte de productos más vendidos',
  'appComparative.createReport': 'Crear Reporte',
  'appComparative.createReportQuestion': '¿Esta seguro de crear un nuevo reporte?',
  'appComparative.createReportSuccess': 'Se ha creado un nuevo reporte, entra para editarlo!',
  'appComparative.creator': 'Creador',
  'appComparative.reportDetail': 'Detalle del reporte',
  'appComparative.priceComparison': 'Comparativa de precios',
  'appComparative.app': 'Aplicación',
  'appComparative.addApp': 'Agregar aplicación',
  'appComparative.questionSend': '¿Estas seguro de guardar?',
  'appComparative.updateSuccess': 'Se ha acualizado el registro con exito!',
  'appComparative.sendEmailButton': 'Enviar Correo',
  'appComparative.detailModal': 'Detalle de la comparativa de precios',
  'appComparative.cancelEditModal': '¿Esta seguro de cancelar la edición?',

  'coupons.title': 'Cupones',
  'coupons.subTitle': 'Listado de cupones',
  'coupons.buttonAdd': 'Crear cupon',
  'coupons.titleForm': 'Formulario de cupones',
  'coupons.coupon': 'Cupón',
  'coupons.prizeType': 'Tipo de Premio',
  'coupons.prize': 'Monto del Cupón',
  'coupons.prize2': 'Porcentaje de Descuento',
  'coupons.ruleMinAmount': 'Monto Mínimo de la Orden para poder redimirlo',
  'coupons.ruleMaxAmount': ' Monto máximo de Regalo para el Cupón',
  'coupons.productAtLeast': '# Mínimo de productos que deben ser incluidos',
  'coupons.target': 'Target',
  'coupons.general': 'General',
  'coupons.listUsers': 'Lista de usuarios',
  'coupons.type': 'Tipo de Cupón',
  'coupons.byProducts': 'Por productos en el carrito',
  'coupons.byCategory': 'Por productos de la categoria',
  'coupons.byDepartment': 'Por productos del departamento',
  'coupons.monto': 'Monto',
  'coupons.purchasePercentage': 'Porcentaje de la Compra',
  'coupons.createCoupon': 'Crear cupón',
  'coupons.couponModal': 'Esta seguro de crear este cupón',
  'coupons.successCreate': 'Se creo el cupón con éxito',
  'coupons.quantityTotal': '# Total de Usos para el Cupón',
  'coupons.quantityTotalByUser': 'Máximo de Usos por Usuario para el Cupón',
  'coupons.allCountry': 'Extender el cupón por todo el país',
  'coupons.allCountryOK': 'Ten en cuenta que los usuarios podra usar el cupón en cualquiera de los proveedores del país.',

  'baskets.name': 'Canastillas',
  'baskets.permissionsCreate': 'Crear canastillas',
  'baskets.warehouse': 'Almacen',
  'baskets.code': 'Código',
  'baskets.listName': 'Listado de canastillas',
  'baskets.createButton': 'Crear canastillas',
  'baskets.editButton': 'Editar canastilla',
  'baskets.selectedWarehouse': 'Selecciona el almacen',
  'baskets.confirmAlert': '¿Seguro de crear las canastillas?',
  'baskets.confirmEditAlert': '¿Seguro de actualizar la canastilla?',
  'baskets.changeWarehouse': 'Cambiar de almacen',
  'baskets.returnBaskets': 'Devolución de canastillas',
  'baskets.returnBasketsButton': 'Devolver canastillas',
  'baskets.pilotName': 'Nombre del piloto',
  'baskets.return': 'Recibir canastas',
  'baskets.errorCompleteProcess': 'Debe recibir mínimo una canastastilla.',
  'baskets.confirmDeliveryBaskets': 'Seguro de recibir las canastillas',
  'baskets.openProcess': 'Proceso Abierto',
  'baskets.closed': 'Cerrado',
  'baskets.status0': 'Sin novedad',
  'baskets.status1': 'Perdido',
  'baskets.status2': 'Dañado',
  'baskets.basket': 'Canastilla',
  'baskets.basketSuccessModal': 'Se devolvieron las canastillas con exito.',
  'baskets.quantity': 'Cantidad de canastillas',

  'print.name': 'Ip de la impresora',

  'ads.title': 'Anuncios',
  'ads.createAd': 'Crear Anuncios',
  'ads.editAd': 'Editar Anuncios',
  'ads.formAds': 'Formualarios de anuncios',
  'ads.inAppMesagge': 'inAppMesagge',
  'ads.acept': '¿Seguro de crear el anuncio?',
  'ads.aceptEdit': '¿Seguro de editar el anuncio?',
  'ads.aceptEditState': '¿Seguro de cambiar el  estado del anuncio?',
  'ads.successCreate': 'Se ha creado el anuncio con exito!',
  'ads.successEdit': 'Se ha editado el anuncio con exito!',
  'ads.available': 'Disponible',

  'orderReceipts.title': 'Order Receipts',
  'orderTransaction.titlle': 'Order Transactions',
  'orderTransaction.createdAt': 'Created At',
  'orderTransaction.amount': 'Amount',
  'orderTransaction.totalAmount': 'Total Amount',
  'orderTransaction.transacionId': 'Transaction ID',
  'orderTransaction.paymentMethod': 'Payment Method',
  'orderTransaction.bankAccount': 'Bank Account',
  'orderTransaction.status': 'Status'
}

export default {
  locale: 'es-CO',
  antdData,
  localeData,
  messages,
}
